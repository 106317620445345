import React from 'react';
import styled from 'styled-components';

import Heading from '@/atoms/Heading';

import media from '@/styles/media';
import scale from '@/styles/scale';

const Container = styled.header`
  padding-top: ${scale(2)};

  ${media.md`
    padding-top: ${scale(3)};
  `}
`;

export default ({ title, ...props }) => (
  <Container {...props}>
    <Heading h={1} as="h1" hidden>
      {title}
    </Heading>
  </Container>
);
