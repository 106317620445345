import React, { useContext } from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';

import ProductFiltersContext from '@/context/ProductFiltersContext';

import Grid from '@/atoms/Grid';
import Heading from '@/atoms/Heading';
import Text from '@/atoms/Text';

import CategoryHeader from '@/components/CategoryHeader';
import ProductGrid from '@/components/ProductGrid';
import SchemaOrg from '@/components/SchemaOrg';
import Head from '@/components/Head';

import scale from '@/styles/scale';
import media from '@/styles/media';

const DescriptionHeading = styled(Heading).attrs({ h: 5 })`
  margin-bottom: ${scale(0.25)};

  ${media.md`
    margin-bottom: ${scale(0.5)};
  `}
`;

const Description = styled(Grid.Item)`
  margin-bottom: ${scale(2)};
`;

export default ({ data, pageContext, location }) => {
  const {
    collection: { wines, description },
  } = data.graph;
  const [productFilters] = useContext(ProductFiltersContext);

  return (
    <>
      <Head title={pageContext.pageTitle} canonical={location.pathname} />
      <SchemaOrg
        type="breadcrumbs"
        navigationTags={pageContext.defaultNavigationTags}
      />

      <Grid.Container as="section">
        <CategoryHeader title={pageContext.pageTitle} />

        {description && (
          <Grid>
            <Description w={[1, 2 / 3, 2 / 3, 3 / 4]}>
              <DescriptionHeading>collection</DescriptionHeading>
              <Text>{description}</Text>
            </Description>
          </Grid>
        )}

        <ProductGrid
          filters={productFilters}
          products={wines}
          tileSize="small"
          hideSoldOut
        />
      </Grid.Container>
    </>
  );
};

export const query = graphql`
  query($id: ID!) {
    graph {
      collection(where: { id: $id }) {
        description
        wines(where: { status: PUBLISHED }, orderBy: sku_ASC) {
          ...WineData
        }
      }
    }
  }
`;
